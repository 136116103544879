import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, COMPONENTS, Dialog } from 'sw-ui';

import Search from '../../../../components/Search';

import { getMoment, isAfterDate, formatDate, diffDays, isMoment } from '../../../../bi/utils/formatDate';

import { DATE } from '../../../../constants/time';
import { REPORTLABALES } from '../../../../bi/constants/reports';
import { TRIPBUTTONS } from '../../../../bi/constants/trips';

import styles from '../../styles/report.module.scss';

const LABELS = {
  DATE: 'Дата',
  UNLOADING_PERIOD: limit => `Период выгрузки должен быть не более ${limit} календарных дней`,
  ERROR_MSG: 'Ошибка при скачивании отчета. Повторите попытку позже',
};

class PaymentCard extends Component {
  static propTypes = {
    onDownload: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      startDate: getMoment(),
      endDate: getMoment(),
      maxDate: getMoment(),
      loadingButton: false,
      flagMsgError: false,
      tooltipMsg: LABELS.UNLOADING_PERIOD(this.props.limit),
    };
  }

  handleChangeDate = (field, value) => {
    if (!isMoment(value)) {
      return;
    }

    const { endDate } = this.state;

    if (isAfterDate(value, endDate)) {
      this.setState({
        endDate: value,
      });
    }

    this.setState({
      [field]: value,
    });
  }

  handleDownload = () => {
    const { startDate, endDate } = this.state;

    this.setState({
      loadingButton: true,
    });

    return this.props.onDownload(formatDate(startDate, DATE), formatDate(endDate, DATE))
    .catch(() => {
      this.setState({
        flagMsgError: true,
      });
    })
    .finally(() => {
      this.setState({
        loadingButton: false,
      });
    });
  };

  render() {
    const {
      startDate,
      endDate,
      maxDate,
      loadingButton,
      tooltipMsg,
      flagMsgError,
    } = this.state;
    const { DIALOG: { MIDDLE }, BUTTON: { THEME: { FLAT } } } = COMPONENTS;

    const { limit } = this.props;

    const disabledMount = diffDays(startDate, endDate) > limit;

    const renderMsgError = flagMsgError ? (
      <div>
        <Dialog
          show={ flagMsgError }
          onClick={ () => this.setState({ flagMsgError: false }) }
          width={ MIDDLE }
        >
          <div className={ styles.dialog }>
            <p>{ LABELS.ERROR_MSG }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button
                label={ TRIPBUTTONS.CLOSE }
                theme={ FLAT }
                onClick={ () => this.setState({ flagMsgError: false }) }
              />
            </div>
          </div>
        </Dialog>
      </div>
      ) : null;


    return (
      <div className={ `${styles.main} ${styles.main_search}` }>
        <div className={ ` ${styles.header} ${styles.header_autocomplite}` } >
          <Search
            labelButton={ REPORTLABALES.DOWNLOAD }
            startDate={ startDate }
            endDate={ endDate }
            maxDate={ maxDate }
            disabled={ disabledMount }
            waitingResponse={ loadingButton }
            tooltipMsg={ tooltipMsg }
            dateStartPickerLabels={ LABELS.DATE }
            dateEndPickerLabels={ LABELS.DATE }
            onChangeDate={ this.handleChangeDate }
            onDownload={ this.handleDownload }
            maxDateLimit={ maxDate }
            showTooltip={ disabledMount }
          />
        </div>
        { renderMsgError }
      </div>
    );
  }
}

export default PaymentCard;
