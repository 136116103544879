import renderComponents from './components';

const NAME = 'sw-report-taxi-page';

class ReportTaxi {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const businessService = this.context.getService('Business');
    const appService = context.getService('App');
    const { params } = appService.get();
    this.page = renderComponents(this.box, {
      businessService,
      params,
    });
  }

  goBack = () => {
    history.back();
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, ReportTaxi);
}
