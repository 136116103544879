import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import Search from '../../../../components/Search';

import { getMoment, isAfterDate, formatDate } from '../../../../bi/utils/formatDate';

import { DATE } from '../../../../constants/time';
import SEARCH from '../../../../bi/constants/search';

import styles from '../../styles/report.module.scss';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 40,
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  marginTop: '28px',
};

const ERROR = {
  ID: 'Вы ввели не существующий Id или название компании',
  PERIOD: 'В выбраном диапазоне отсутствуют данные для формирования документа',
};

const LABELS = {
  AUTOCOMPLITE: 'Компания: (название или номер)',
};

class GeneralActCompletedWorks extends Component {
  static propTypes = {
    tripService: PropTypes.object.isRequired,
    businessService: PropTypes.object.isRequired,
    nameList: PropTypes.string,
    maxDate: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    description: PropTypes.string,
    tooltipMsg: PropTypes.string,
    labelButton: PropTypes.string,
    disabledButton: PropTypes.bool,
    onDownload: PropTypes.func.isRequired,
    datePickerLabels: PropTypes.string,
    renderOption: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  constructor(props) {
    super(props);
    const { responseGeneralActStatus } = props.businessService.get();

    this.state = {
      responseGeneralActStatus,
      disabledButton: this.props.disabledButton,
      showTooltip: false,
      tooltipMsg: this.props.tooltipMsg,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      waitingResponse: false,
      company: {
        label: '',
        suggests: [],
      },
    };
  }

  static defaultProps = {
    maxDate: getMoment(),
    startDate: getMoment(),
    endDate: getMoment(),
    nameList: '',
    description: '',
    tooltipMsg: '',
    labelButton: SEARCH.DOWNLOAD,
    renderOption: null,
    disabledButton: true,
    datePickerLabels: SEARCH.DATE,
  };

  componentDidMount() {
    const { businessService } = this.props;

    this.unsubscribe = businessService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = ({ responseGeneralActStatus }) => this.setState({
    responseGeneralActStatus,
  });

  handleChangeDate = (field, value) => {
    const { endDate } = this.state;

    if (isAfterDate(value, endDate)) {
      this.setState({
        endDate: value,
        showTooltip: false,
      });
    }

    this.setState({
      [field]: value,
      showTooltip: false,
    });
  }

  toggleWaitingResponse = () => {
    const { responseGeneralActStatus } = this.state;

    this.setState({ waitingResponse: !this.state.waitingResponse });

    if (responseGeneralActStatus) {
      return this.setState({
        tooltipMsg: ERROR.PERIOD,
        showTooltip: true,
      });
    }
    return this.setState({ showTooltip: false });
  }

  handleDownload = () => {
    const { selectedCompany, startDate, endDate } = this.state;
    this.toggleWaitingResponse();

    return this.props.onDownload(selectedCompany, formatDate(startDate, DATE), formatDate(endDate, DATE))
      .then(this.toggleWaitingResponse)
      .catch(this.toggleWaitingResponse);
  };

  handleSelectSuggest = (cValue, { CompanyId, ShortCompanyName, CompanyName }) => {
    this.validationEror();
    this.setState((state) => {
    // eslint-disable-next-line no-param-reassign
      state.company = {
        ...this.state.company,
        label: `${ShortCompanyName || CompanyName} (${CompanyId})`,

      };

      return state;
    });
    this.setState({
      selectedCompany: CompanyId,
      showTooltip: false,
    });
  };

  handleChangeSuggest = (value) => {
    this.setState({
      tooltipMsg: ERROR.ID,
      disabledButton: true,
      company: {
        ...this.state.company,
        label: value,
      },
    }, () => {
      if (value.length) {
        this.props.tripService.autocompleteCompany(this.state.company.label).then((res) => {
          this.setState({
            company: {
              ...this.state.company,
              suggests: res,
            },
            showTooltip: false,
          });
        });
      }
    });
  };

  validationEror = () => {
    const { company: { suggests } } = this.state;

    if (!suggests.length) {
      return this.setState({ tooltipMsg: ERROR.ID, disabledButton: true });
    }

    return this.setState({ disabledButton: false });
  }

  renderEmpty = () => {
    const { nameList } = this.props;

    return nameList && nameList.length && (
      <div className={ styles.content }>
        <div className='empty-list'>{ nameList }</div>
      </div>
    );
  };

  render() {
    const {
      waitingResponse,
      startDate,
      endDate,
      company,
      showTooltip,
      disabledButton,
      tooltipMsg,
    } = this.state;

    const {
      maxDate,
      description,
      labelButton,
      renderOption,
      datePickerLabels,
    } = this.props;

    const descriptionHtml = description && description.length && <div className={ styles.description }>{ description }</div>;
    const optionHtml = renderOption || this.renderEmpty();

    return (
      <div className={ `${styles.main} ${styles.main_autocomplite}` }>
        { descriptionHtml }
        <div className={ ` ${styles.header} ${styles.header_autocomplite}` } >
          <div className={ styles['autocomplete-input'] }>
            <label className={ styles.label_autocomlite }>{LABELS.AUTOCOMPLITE}</label>
            <Autocomplete
              menuStyle={ MENUSTYLE }
              value={ company && company.label }
              items={ company.suggests }
              getItemValue={ item => item.ShortCompanyName || item.CompanyName }
              onSelect={ (cValue, value) => this.handleSelectSuggest(cValue, value) }
              onChange={ (event, value) => this.handleChangeSuggest(value) }
              renderItem={ item =>
              (<div className='autocomplete-item'>
                { `${item.ShortCompanyName || item.CompanyName} (${item.CompanyId})` }
              </div>)
            }
            />
          </div>
          <Search
            labelButton={ labelButton }
            startDate={ startDate }
            endDate={ endDate }
            maxDate={ maxDate }
            disabled={ disabledButton }
            waitingResponse={ waitingResponse }
            tooltipMsg={ tooltipMsg }
            datePickerLabels={ datePickerLabels }
            onChangeDate={ this.handleChangeDate }
            onDownload={ this.handleDownload }
            maxDateLimit={ maxDate }
            showTooltip={ showTooltip }
          />
        </div>
        { optionHtml }
      </div>
    );
  }
}

export default GeneralActCompletedWorks;
