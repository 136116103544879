import renderComponents from './components';

const NAME = 'sw-report';

class ReportModule {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const businessService = this.context.getService('Business');
    const reviseActTestService = this.context.getService('ReviseActTest');
    const appService = context.getService('App');
    const tripService = context.getService('Trip');
    const { params } = appService.get();
    const moveToReportResult = this.moveToReportResult;

    this.page = renderComponents(this.box,
      {
        businessService,
        tripService,
        reviseActTestService,
        params,
        moveToReportResult,
      });
  }

  goBack = () => {
    history.back();
  };

  moveToReportResult = (reportId) => {
    const path = `/reviseact/${reportId}`;
    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, ReportModule);
}
