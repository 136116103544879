import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AjaxButton from '../../../../components/ajaxButton';
import MaterialIcon from '../../../../components/materialIcon';
import { formatDate } from '../../../../bi/utils/formatDate';

import styles from './styles.module.css';
import { FORMATDATETIME } from '../../../../constants/time';

class ReviseActTest extends Component {
  static propTypes = {
    reviseActTestService: PropTypes.object.isRequired,
    onMoveToReport: PropTypes.func.isRequired,
  };

  state = {
    waitingResponse: false,
    listAct: [],
  };

  UNSAFE_componentWillMount() {
    this.props.reviseActTestService.getTestList();
    this.unsubscribe = this.props.reviseActTestService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = () => this.setState({
    listAct: this.props.reviseActTestService.get().listAct,
  });

  handleNewTest = () => {
    this.setState({
      waitingResponse: true,
    }, () => this.props.reviseActTestService.addNewTest()
      .then(() => this.setState({
        waitingResponse: false,
      })));
  };

  renderListAct = () => {
    const { listAct } = this.state;
    const { reviseActTestService } = this.props;

    if (listAct.length === 0) {
      return (
        <div className={ styles['no-tests'] }>
          Тесты отсутствуют
        </div>
      );
    }

    return listAct.map((item, index) => (
      <div
        key={ item.Id }
        className={ `${styles.row} ${styles['test-item']}` }
        onClick={
          () => item.Completed && this.props.onMoveToReport(item.Id)
        }
      >
        <div className={ `${styles.col} ${styles['col-2-7']}` }>
          {`${index + 1}. ${item.UserName}`}
        </div>
        <div className={ `${styles.col} ${styles['col-1-7']} ${styles.status}` }>
          <MaterialIcon
            icon='fiber_manual_record'
            className={ `${styles[reviseActTestService.TESTSTATUS[item.Status].CLASS]} ${styles['status-item']}` }
          />
          <span className={ styles['status-text'] }>
            {reviseActTestService.TESTSTATUS[item.Status].MESSAGE}
          </span>
        </div>
        <div className={ `${styles.col} ${styles['col-1-7']}` }>
          {item.CompanyCount}
        </div>
        <div className={ `${styles.col} ${styles['col-1-7']}` }>
          {item.Failed || 'Не вычислено'}
        </div>
        <div className={ `${styles.col} ${styles['col-1-7']}` }>
          {item.Completed ?
            formatDate(item.Completed, FORMATDATETIME) :
            reviseActTestService.TESTSTATUS[item.Status].MESSAGE
          }
        </div>
      </div>
    ));
  };

  render() {
    return (
      <div className={ styles.main }>
        <div className={ styles['button-box'] }>
          <AjaxButton
            label='Добавить тест в очередь'
            onClick={ this.handleNewTest }
            loading={ this.state.waitingResponse }
          />
        </div>
        <div className={ `${styles.row} ${styles['row-title']}` }>
          <div className={ `${styles.col} ${styles['col-2-7']} ${styles.title}` } >
            Логин
          </div>
          <div className={ `${styles.col} ${styles['col-1-7']} ${styles.title}` } >
            Статус
          </div>
          <div className={ `${styles.col} ${styles['col-1-7']} ${styles.title}` } >
            Компании с актами сверки
          </div>
          <div className={ `${styles.col} ${styles['col-1-7']} ${styles.title}` } >
            Компании с проблемными актами сверки
          </div>
          <div className={ `${styles.col} ${styles['col-1-7']} ${styles.title}` } >
            Дата окончания теста
          </div>
        </div>
        <div className={ styles['list-act-wrap'] }>
          { this.renderListAct() }
        </div>
      </div>
    );
  }
}

export default ReviseActTest;
