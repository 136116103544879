import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import CircularLoaders from '../../components/loaders';
import { FlatButton } from '../../components/button';

import styles from './styles.module.css';

class ReviseAct extends Component {
  static propTypes = {
    appService: PropTypes.object.isRequired,
    goBack: PropTypes.func.isRequired,
    reviseActTestService: PropTypes.object.isRequired,
  };

  state = {
    testList: [],
  };

  UNSAFE_componentWillMount() {
    const testId = this.props.appService.get().params.reportId;
    this.props.reviseActTestService.testResult(testId)
      .then(res => this.setState({
        testList: res,
      }));
  }

  renderCompanyList = () => this.state.testList.map((item, index) => (
    <div key={ item.Id } className={ `${styles.row} ${styles['test-item']}` }>
      <div className={ `${styles.col} ${styles['col-2-7']}` }>
        {`${index + 1}) ${item.CompanyName}`}
      </div>
      <div className={ `${styles.col} ${styles['col-1-7']}` }>
        {item.ShortCompanyName}
      </div>
    </div>
  ));

  render() {
    if (!this.state.testList.length) return <CircularLoaders wrapClassName='content-loader-wrap' />;

    return (
      <div className={ styles.main }>
        <FlatButton onClick={ this.props.goBack }>
          <i className='material-icons'>keyboard_backspace</i> назад
        </FlatButton>
        <div className={ styles['main-title'] }>Список компаний с проблемными актами сверки</div>
        <div className={ styles['test-list-wrap'] }>
          <div className={ `${styles.row} ${styles['row-title']}` } >
            <div className={ `${styles.col} ${styles['col-2-7']} ${styles.title}` }>
              Полное наименование
            </div>
            <div className={ `${styles.col} ${styles['col-1-7']} ${styles.title}` }>
              Краткое наименование
            </div>
          </div>
          { this.renderCompanyList() }
        </div>
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <ReviseAct
      appService={ opts.appService }
      goBack={ opts.goBack }
      reviseActTestService={ opts.reviseActTestService }
    />
  );

  return root;
};

export default renderComponents;
